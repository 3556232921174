import { Category } from '../categories';
import { WidgetType } from '../widgetTypes';

export const switchFieldConstructor = ({ pathToValue, category = Category.OTHER, label, nestedComponent = false, }) => ({
    [pathToValue]: {
        widgetType: WidgetType.SWITCH,
        category: nestedComponent ? undefined : category,
        widgetConfig: {
            label: label
        },
    },

});
