import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const dropdownFieldConfigConstructor = ({ pathToValue, category = Category.OTHER, dropdownValues, label, fieldWidth = null, nestedComponent = false, }) => ({
    [pathToValue]: {
        widgetType: WidgetType.DROPDOWN,
        category: nestedComponent ? undefined : category,
        widgetConfig: {
            label,
            values: dropdownValues,
            width: fieldWidth
        },
    },
});
