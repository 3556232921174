import { Category } from '../categories';
import { WidgetType } from '../widgetTypes';

export const rangeFieldConfigConstructor = ({ pathToValue, category = Category.OTHER, label, fieldWidth = null, nestedComponent = false, }) => ({
    [pathToValue]: {
        widgetType: WidgetType.RANGE_FILTER,
        category: nestedComponent ? undefined : category,
        widgetConfig: {
            label: label,
            width: fieldWidth
        },
    }
});
