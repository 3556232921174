import { Category } from "../categories"
import { WidgetType } from "../widgetTypes"

export const autocompleteFieldConstructor = ({ pathToValue, category = Category.OTHER, label, fieldWidth = 240, nestedComponent = false, }) => ({
    [pathToValue]: {
        category: nestedComponent ? undefined : category,
        widgetType: WidgetType.AUTOCOMPLETE,
        widgetConfig: {
            label,
            width: fieldWidth,
            autoLoadValues: true,
        },
    },
})
