import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const numberFieldConstructor = ({ pathToValue, category = Category.OTHER, label, fieldWidth = 48, withComparisonOperators = true, nestedComponent = false, }) => ({
  [pathToValue]: {
        widgetType: WidgetType.SIMPLE_NUMBER,
        category: nestedComponent ? undefined : category,
        widgetConfig: {
            width: fieldWidth,
            label: label,
            operators: withComparisonOperators ?  ["eq", "gt", "lt", "gte", "lte"] : [],
        },
    },
});
