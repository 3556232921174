import _ from 'lodash';
import {ReactComponent as PatientsIcons} from 'assets/img/couple-users-silhouette_WHITE.svg';
import {ReactComponent as ClinicsIcon} from 'assets/img/Clinic_WHITE.svg';
import ContactMail from '@material-ui/icons/ContactMail';
import LocalHospital from '@material-ui/icons/LocalHospital';
import FilterIcon from '@material-ui/icons/FilterList';
import {ReactComponent as PatientIcon} from 'assets/img/patient-icon.svg';
import Home from '@material-ui/icons/Home';

const routes = {
    LANDING: {
        path: '/',
        icon: Home,
        subtitle: 'global.home'
    },
    LOGOUT: {
        path: '/logout',
    },
    RESET_PASSWORD: {
        path: '/password-reset/:token',
    },
    CASES: {
        path: '/cases',
        icon: PatientsIcons,
        subtitle: 'global.patients'
    },
    CASE_EDIT: {
        path: '/cases/:caseBusinessId',
        relativePath: '/:caseBusinessId',
        pathWithId: (caseBusinessId) => `/cases/${caseBusinessId}`,
    },
    CLINICS: {
        path: '/clinics',
        icon: ClinicsIcon,
        subtitle: 'global.clinics'
    },
    COHORT_FILTERS: {
        path: '/cohort-filters',
        icon: FilterIcon,
        subtitle: 'global.filters'
    },
    FILTERS: {
        path: '/cohort-filters/filters',
    },
    COHORTS: {
        path: '/cohort-filters/cohorts',
    },
    FILTER_NEW: {
        icon: FilterIcon,
        subtitle: 'global.filters',
        path: '/cohort-filters/filter/new',
    },
    FILTER_EDIT: {
        path: '/cohort-filters/filter/:filterId',
        pathWithId: (filterId) => `/cohort-filters/filter/${filterId}`,
    },
    COHORT_EDIT: {
        path: '/cohort-filters/cohort/:cohortId',
        pathWithId: (cohortId) => `/cohort-filters/cohort/${cohortId}`,
    },
    PATIENT_PAGE: {
        path: '/patient/:patientId',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId) => `/patient/${patientId}`,
    },
    PATIENT_PAGE_PROGRAM: {
        path: '/patient/:patientId/program/:programId',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId, programId) => `/patient/${patientId}/program/${programId}`
    },
    PATIENT_PAGE_ANAMNESE: {
        path: '/patient/:patientId/anamnese',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId) => `/patient/${patientId}/anamnese`
    },
    PATIENT_PAGE_MEDICATION_PLAN: {
        path: '/patient/:patientId/medication-plan',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId) => `/patient/${patientId}/medication-plan`
    },
    PATIENT_PAGE_LABORATORY: {
        path: '/patient/:patientId/laboratory',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId) => `/patient/${patientId}/laboratory`
    },
    PATIENT_PAGE_MY_RISK: {
        path: '/patient/:patientId/my-risk',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId) => `/patient/${patientId}/my-risk`
    },
    CLINICS_EDIT: {
        path: '/clinics/:clinicBusinessId',
        relativePath: '/:clinicBusinessId',
        pathWithId: (clinicBusinessId) => `/clinics/${clinicBusinessId}`,
    },
    CLINICS_CREATION: {
        path: '/clinics/new',
        relativePath: '/new',
    },
    MANAGED_CARE: {
        path: '/managed-care',
        icon: ContactMail,
        subtitle: 'global.managed-care'
    },
    CARE_PROVIDERS: {
        path: '/care-providers',
        icon: LocalHospital,
        subtitle: 'global.care-providers'
    },
    MANAGED_CARE_PROGRAM_TEMPLATES: {
        path: '/managed-care/programs',
    },
    REGISTRATION_WITH_INVITE: {
        path: '/registration/:inviteToken',
    },
    REGISTRATION: {
        path: '/registration',
    },
    WELCOME: {
        path: '/welcome',
    },
    ROLE_SELECTOR: {
        path: '/role-selector',
    },
    PATIENT_PAGE_HISTORY_LOG: {
        path: '/patient/:patientId/history-log',
        icon: PatientIcon,
        subtitle: 'global.patient.page.title',
        pathWithId: (patientId) => `/patient/${patientId}/history-log`
    }
};

export const LANDING = routes.LANDING;
export const LOGOUT = routes.LOGOUT;
export const RESET_PASSWORD = routes.RESET_PASSWORD;
export const CASES = routes.CASES;
export const CASE_EDIT = routes.CASE_EDIT;
export const CLINICS = routes.CLINICS;
export const MANAGED_CARE = routes.MANAGED_CARE;
export const CARE_PROVIDERS = routes.CARE_PROVIDERS;
export const MANAGED_CARE_PROGRAM_TEMPLATES = routes.MANAGED_CARE_PROGRAM_TEMPLATES;
export const CLINICS_EDIT = routes.CLINICS_EDIT;
export const CLINICS_CREATION = routes.CLINICS_CREATION;
export const PATIENT_PAGE = routes.PATIENT_PAGE;
export const PATIENT_PAGE_PROGRAM = routes.PATIENT_PAGE_PROGRAM;
export const PATIENT_PAGE_ANAMNESE = routes.PATIENT_PAGE_ANAMNESE;
export const PATIENT_PAGE_MEDICATION_PLAN = routes.PATIENT_PAGE_MEDICATION_PLAN;
export const PATIENT_PAGE_LABORATORY = routes.PATIENT_PAGE_LABORATORY;
export const PATIENT_PAGE_MY_RISK = routes.PATIENT_PAGE_MY_RISK;
export const REGISTRATION = routes.REGISTRATION;
export const REGISTRATION_WITH_INVITE = routes.REGISTRATION_WITH_INVITE;
export const WELCOME = routes.WELCOME;
export const ROLE_SELECTOR = routes.ROLE_SELECTOR;
export const COHORT_FILTERS = routes.COHORT_FILTERS;
export const FILTERS = routes.FILTERS;
export const COHORTS = routes.COHORTS;
export const FILTER_NEW = routes.FILTER_NEW;
export const FILTER_EDIT = routes.FILTER_EDIT;
export const COHORT_EDIT = routes.COHORT_EDIT;
export const PATIENT_PAGE_HISTORY_LOG = routes.PATIENT_PAGE_HISTORY_LOG;

export const authorizedRoutes = [LANDING, CASES, CASE_EDIT, CLINICS, MANAGED_CARE, CLINICS_EDIT, CLINICS_CREATION];

export const pathToRouteMap = Object.values(routes).reduce((result, item) => {
    result[item.path] = item;
    return result;
}, {});

export default Object.values(routes);

export function routeTo(path, props) {
    const prevPath = _.get(props, 'location')
        ? _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '')
        : undefined;
    props.history.push(path, {prevPath});
}

export function prevPath(props) {
    return _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '');
}

export function newTab(path) {
    window.open(window.location.origin + path, '_blank');
}
