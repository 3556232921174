import { WidgetType } from "../widgetTypes";
import { Category } from "../categories";
export const switchWithAutoCompleteFieldConfigConstructor = ({
    pathToSelectValues,
    pathToSwitchQuestion,
    fieldLabel,
    autocompleteLabel,
    category = Category.OTHER,
    autocompleteFieldWidth = 350,
    allowOrCondition = false,
}) => ({
    [pathToSwitchQuestion]: {
        widgetType: WidgetType.SWITCH_WITH_AUTOCOMPLETE,
        category,
        defaultRuleProperties: {
            nestedPath: pathToSelectValues,
            value: {
                type: "switchWithNestedSelect",
                switchValue: {
                    field: pathToSwitchQuestion,
                    value: true,
                },
                selectValue: {
                    field: pathToSelectValues + ".value",
                    value: "",
                },
            },
        },
        widgetConfig: {
            autoLoadValues: true,
            autocompleteLabel: autocompleteLabel,
            width: autocompleteFieldWidth,
            label: fieldLabel,
            allowOrCondition
        },
    },
});
