import { geneValues, sampleLocations } from "../../patient/anamnesis/component/section/MutationModalForm";
import { ProblemSeverityEnum, DiscomfortLevelEnum } from "../../patient/anamnesis/enums";
import { MentalDiscomfortLevelEnum } from "../../patient/anamnesis/enums/MentalDiscomfortLevel.copy";
import { Category } from "./categories";
import { createLabValueConfig } from "./createNestedConfig";
import {
    dropdownFieldConfigConstructor,
    rangeFieldConfigConstructor,
    switchWithAutoCompleteFieldConfigConstructor,
    switchFieldConstructor,
    switchWithSelectFieldConstructor,
    numberFieldConstructor,
    autocompleteFieldConstructor,
} from "./fieldContructors";
import { ageRangeFieldConstructor } from "./fieldContructors/ageRangeField";
import { nestedFieldConstructor } from "./fieldContructors/nestedField";

const dropDownValues = {
    treatmentStatus: [
        { value: "TREATED", label: "global.treated" },
        { value: "UNTREATED", label: "global.untreated" },
    ],
    problemSeverity: Object.values(ProblemSeverityEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    mentalProblemsSeverity: Object.values(MentalDiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    discomfortLevel: Object.values(DiscomfortLevelEnum).map((value) => ({
        value,
        label: `anamnesis.quality.${value}`,
    })),
    bloodCountChange: [
        "LEUKOCYTE_INCREASE",
        "LEUKOCYTE_REDUCTION",
        "ANAMIE",
        "INCREASE_IN_HEMATOCRIT",
        "INCREASE_IN_PLATELETS",
        "REDUCTION_IN_PLATELETS",
    ].map((value) => ({
        value,
        label: `anamnesis.hematology.${value}`,
    })),
    CHRSScoreType: ["low", "intermediate", "high"].map((value) => ({
        value,
        label: value ? `my-risk.score-label.${value}` : "",
    })),
    chipSampleLocations: sampleLocations.map((value) => ({
        value,
        label: `anamnesis.chip.source.${value}`,
    })),
};

export const fields = {
    ...dropdownFieldConfigConstructor({
        category: Category.GENERAL,
        pathToValue: "bio_sex",
        dropdownValues: [
            { value: "MAN", label: "global.male-bio-sex" },
            {
                value: "WOMAN",
                label: "global.female-bio-sex",
            },
        ],
        label: "anamnesis.general.genderLabel",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.RISK_SCORE,
        pathToValue: "risk.hematology_score_type",
        dropdownValues: dropDownValues.CHRSScoreType,
        label: "Clonal Hematopoiesis Risk Score (CHRS)",
    }),
    ...rangeFieldConfigConstructor({
        category: Category.RISK_SCORE,
        pathToValue: "risk.full_risk_percent",
        label: "my-risk.cardiovascular-risk-percent",
        fieldWidth: 56,
    }),
    ...rangeFieldConfigConstructor({
        category: Category.GENERAL,
        pathToValue: "anamnesis.GENERAL.height",
        label: "anamnesis.general.heightLabel",
        fieldWidth: 56,
    }),
    ...rangeFieldConfigConstructor({
        category: Category.GENERAL,
        pathToValue: "anamnesis.GENERAL.weight",
        label: "anamnesis.general.weightLabel",
        fieldWidth: 56,
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIO_DISEASE.hasHealthyDiet",
        label: "anamnesis.cardiovascular.hasHealthyDiet",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIO_DISEASE.hasTimePressureAndStress",
        label: "anamnesis.cardiovascular.hasTimePressureAndStress",
    }),
    ...switchWithSelectFieldConstructor({
        pathToSwitchQuestion: "anamnesis.CCS.hasCCS",
        pathToSelectValue: "anamnesis.CCS.ccsValue",
        label: "anamnesis.clinical.CCSValueLabelInQuery",
        selectOptions: ["CCS2", "CCS3", "CCS4"],
        category: Category.RISK_SCORE,
    }),
    ...numberFieldConstructor({
        category: Category.CHIP,
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values_count",
        label: "anamnesis.chip.mutationCount",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.ARTHROSE,
        pathToSelectValues: "anamnesis.ARTHROSE.haveArthroseData.values",
        pathToSwitchQuestion: "anamnesis.ARTHROSE.haveArthrose",
        autocompleteLabel: "anamnesis.arthrose.which-joint",
        fieldLabel: "anamnesis.arthrose.title",
    }),
    ...ageRangeFieldConstructor({
        pathToValue: "age",
        label: "global.age",
        category: Category.GENERAL,
        defaultFromValue: 18,
        defaultToValue: 40,
        fieldWidth: 56,
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.smokeStatus",
        label: "anamnesis.cardiovascular.smokeStatus",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.oldSmokeStatus",
        label: "anamnesis.cardiovascular.oldSmokeStatus",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.alcoholDrinkingStatus",
        label: "anamnesis.cardiovascular.alcoholDrinkingStatus",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.RISK_SCORE,
        pathToSwitchQuestion: "anamnesis.NYHA.hasNYHA",
        pathToSelectValue: "anamnesis.NYHA.nyhaValue",
        label: "anamnesis.clinical.NYHAValueLabelInQuery",
        selectOptions: ["NYHA2", "NYHA3", "NYHA4"],
    }),
    ...nestedFieldConstructor({
        category: Category.CHIP,
        label: "anamnesis.chip.mutationData",
        nestedPath: "anamnesis.RISK_FACTOR.chipMutationData.values",
        allowOrCondition: true,
        canExclude: true,
        additionalFieldProperties: {
            "anamnesis.RISK_FACTOR.chipMutationData.values.vaf": {
                operator: "eq",
            },
        },
        nestedConfigs: [
            dropdownFieldConfigConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
                label: "anamnesis.chip.table.gene",
                nestedComponent: true,
                dropdownValues: geneValues,
                fieldWidth: 150,
            }),
            numberFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
                label: "Exon",
                nestedComponent: true,
                fieldWidth: 50,
                withComparisonOperators: false,
            }),
            autocompleteFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
                label: "anamnesis.chip.table.dna",
                fieldWidth: 150,
                nestedComponent: true,
            }),
            autocompleteFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
                label: "anamnesis.chip.table.protein",
                fieldWidth: 150,
                nestedComponent: true,
            }),
            numberFieldConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
                label: "anamnesis.chip.table.vaf",
                fieldWidth: 80,
                nestedComponent: true,
            }),
            dropdownFieldConfigConstructor({
                pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.source",
                nestedComponent: true,
                dropdownValues: dropDownValues.chipSampleLocations,
                label: "Source",
                fieldWidth: 180,
            }),
        ],
    }),
    ...dropdownFieldConfigConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.gene",
        label: "anamnesis.chip.table.gene",
        nestedComponent: true,
        dropdownValues: geneValues,
        fieldWidth: 150,
    }),
    ...numberFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.vaf",
        label: "anamnesis.chip.table.vaf",
        fieldWidth: 80,
        nestedComponent: true,
    }),
    ...numberFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.exon",
        label: "Exon",
        nestedComponent: true,
        fieldWidth: 50,
        withComparisonOperators: false,
    }),
    ...autocompleteFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.dna",
        label: "anamnesis.chip.table.dna",
        fieldWidth: 150,
        nestedComponent: true,
    }),
    ...autocompleteFieldConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.protein",
        label: "anamnesis.chip.table.protein",
        fieldWidth: 150,
        nestedComponent: true,
    }),
    ...dropdownFieldConfigConstructor({
        pathToValue: "anamnesis.RISK_FACTOR.chipMutationData.values.source",
        nestedComponent: true,
        dropdownValues: dropDownValues.chipSampleLocations,
        label: "Source",
        fieldWidth: 180,
    }),
    ...switchFieldConstructor({
        category: Category.OTHER,
        pathToValue: "anamnesis.COMBINED.haveAllergies",
        label: "anamnesis.allergies.title",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.hasCardiovascularDisease",
        label: "anamnesis.cardiovascular.hasCardiovascularDisease",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToSwitchQuestion: "anamnesis.CARDIOVASCULAR.hasSufferingFromDiabetes",
        pathToSelectValue: "anamnesis.CARDIOVASCULAR.diabetesState",
        selectOptions: dropDownValues.treatmentStatus,
        label: "Diabetes mellitus",
    }),
    ...switchFieldConstructor({
        category: Category.COMORBIDITIES,
        pathToValue: "anamnesis.COMBINED.haveAsthma",
        label: "anamnesis.comorbidity.asthma",
    }),
    ...switchFieldConstructor({
        category: Category.HEMATOLOGY,
        pathToValue: "anamnesis.HEMATOLOGY.haveHematoFamilialSyndromOrGenetic",
        label: "anamnesis.hematology.haveHematoFamilialSyndromOrGenetic",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.HEMATOLOGY,
        pathToSelectValues: "anamnesis.HEMATOLOGY.haveActiveHematoDiseaseData.values",
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveActiveHematoDisease",
        autocompleteLabel: "anamnesis.hematology.what-kind",
        fieldLabel: "anamnesis.hematology.haveActiveHematoDisease",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.HEMATOLOGY,
        pathToSelectValues: "anamnesis.HEMATOLOGY.haveFormerHematoDiseaseData.values",
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveFormerHematoDisease",
        autocompleteLabel: "anamnesis.hematology.what-kind",
        fieldLabel: "anamnesis.hematology.haveFormerHematoDisease",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.HEMATOLOGY,
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCount",
        pathToSelectValue: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCountData.values.value",
        nestedPathToSelectValue: "anamnesis.HEMATOLOGY.havePreviousChangeInBloodCountData.values",
        label: "anamnesis.hematology.havePreviousChangeInBloodCount",
        selectOptions: dropDownValues.bloodCountChange,
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.HEMATOLOGY,
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCount",
        pathToSelectValue: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCountData.values.value",
        selectOptions: dropDownValues.bloodCountChange,
        label: "anamnesis.hematology.haveCurrentChangeInBloodCount",
        nestedPathToSelectValue: "anamnesis.HEMATOLOGY.haveCurrentChangeInBloodCountData.values",
    }),
    ...switchWithAutoCompleteFieldConfigConstructor({
        category: Category.HEMATOLOGY,
        pathToSelectValues: "anamnesis.HEMATOLOGY.haveAbdomenUltrasoundData.values",
        pathToSwitchQuestion: "anamnesis.HEMATOLOGY.haveAbdomenUltrasound",
        autocompleteLabel: "anamnesis.hematology.spleen-size-reported",
        fieldLabel: "anamnesis.hematology.spleenExamination",
    }),
    ...switchWithSelectFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToSwitchQuestion: "anamnesis.CARDIOVASCULAR.hasSufferingFromHypercholesterolemia",
        pathToSelectValue: "anamnesis.CARDIOVASCULAR.hypercholesterolemiaState",
        selectOptions: dropDownValues.treatmentStatus,
        label: "anamnesis.cardiovascular.hypercholesterolemia",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.anxiousValue",
        dropdownValues: dropDownValues.mentalProblemsSeverity,
        label: "anamnesis.quality.anxiousOrDepressive",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.discomfortValue",
        dropdownValues: dropDownValues.discomfortLevel,
        label: "anamnesis.quality.painOrDiscomfort",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.washAndDressValue",
        dropdownValues: dropDownValues.problemSeverity,
        label: "anamnesis.quality.selfCareAbility",
    }),
    ...dropdownFieldConfigConstructor({
        category: Category.QUALITY_OF_LIFE,
        pathToValue: "anamnesis.QUALITY_OF_LIFE.dailyActivityValue",
        dropdownValues: dropDownValues.problemSeverity,
        label: "anamnesis.quality.dailyActivityValueShort",
    }),
    ...switchFieldConstructor({
        category: Category.CARDIO_RISK_FACTORS,
        pathToValue: "anamnesis.CARDIOVASCULAR.sportStatus",
        label: "anamnesis.cardiovascular.sportStatus",
    }),
    ...nestedFieldConstructor({
        nestedPath: "medications",
        category: Category.MEDICATIONS,
        label: "medication.ingredient",
        allowAndCondition: true,
        allowOrCondition: true,
        showLabel: false,
        andConditionLabel: "cohort.add-to-group.medication",
        canExclude: true,
        virtualFieldPaths: ["medCriteria"],
        nestedConfigs: [
            autocompleteFieldConstructor({
                pathToValue: "medications.ingredient",
                label: "medication.table.ingredient",
            }),
            dropdownFieldConfigConstructor({
                pathToValue: "medCriteria",
                dropdownValues: [
                    { value: "ACTIVE", label: "cohort.actual-subscription" },
                    { value: "NON_ACTIVE", label: "cohort.past-subscription" },
                    { value: "NEVER_SUBSCRIBED", label: "cohort.never-subscribed" },
                ],
                label: "cohort.criteria",
                fieldWidth: 208,
                nestedComponent: true,
            }),
        ],
    }),
    ...[
        {
            key: "hemoglobin",
            units: [
                { value: "gperdl", label: "g/dl" },
                { value: "mmolperl", label: "mmol/l" },
            ],
            defaultUnit: "gperdl",
            fieldInStandardUnit: "hemoglobin_in_mmolperl",
        },
        {
            key: "kreatinin",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mikromolperll", label: "μmol/l" },
                { value: "nmolperlml", label: "nmol/ml" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "kreatinin_in_mikromolperl",
        },
        { key: "leukozyten", units: [{ value: "gperl", label: "G/l" }], defaultUnit: "gperl" },
        {
            key: "totalcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "totalcholesterol_in_mmolperl",
        },
        {
            key: "ldlcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "ldlcholesterol_in_mmolperl",
        },
        {
            key: "hdlcholesterol",
            units: [
                { value: "mgperdl", label: "mg/dl" },
                { value: "mmolperldl", label: "mmol/dl" },
            ],
            defaultUnit: "mgperdl",
            fieldInStandardUnit: "hdlcholesterol_in_mmolperl",
        },
        {
            key: "crp",
            units: [
                {
                    value: "mgperdl",
                    label: "mg/dl",
                },
                {
                    value: "mgperl",
                    label: "mg/l",
                },
                {
                    value: "nmolperll",
                    label: "nmol/l",
                },
                {
                    value: "nmolperml",
                    label: "nmol/ml",
                },
            ],
            defaultUnit: "mgperl",
            fieldInStandardUnit: "crp_in_mgperl",
        },
        { key: "mcv", units: [{ value: "fperl", label: "fl" }], defaultUnit: "fperl" },
        { key: "rdw", units: [{ value: "percent", label: "%" }], defaultUnit: "percent" },
        { key: "thrombozyten", units: [{ value: "gperl", label: "G/l" }], defaultUnit: "gperl" },
        { key: "gfr", units: [{ value: "mlpermin", label: "ml/min" }], defaultUnit: "mlpermin" },
    ].reduce((acc, conf) => {
        return {
            ...acc,
            ...createLabValueConfig(conf),
        };
    }, {}),
};
