import Flex from "components/grid/Flex";
import { useTranslation } from "react-i18next";
import {useCreateHistoryLogMutation, useGetHistoryLogsQuery} from "../PatientApi";
import { useParams } from "react-router-dom";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import HmoAvatar from 'components/avatar/HmoAvatar';
import HmoTextField from 'components/hmo-textfield/HmoTextField';
import HmoFilledButton from 'components/button/HmoFilledButton';
import {Checkbox, Typography} from "@material-ui/core";
import _ from 'lodash';
import moment from "moment";
import {useEnqueueError, useEnqueueSuccess} from "components/alert/SnackbarHooks";

const HistoryLog = () => {
    const { t: translate } = useTranslation();
    const { patientId } = useParams();
    const user = useSelector((state) => state.mainReducer.user);
    const { data: historyLogs } = useGetHistoryLogsQuery(patientId);
    const [createHistoryLog] = useCreateHistoryLogMutation();
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const filteredLogs = historyLogs?.filter(log=> !log.isPrivate || log.initiatorUserBusinessId === user.businessId);
    //NOTE LM: Expected structure { 2024: [{...log},{...log},{...log}], 2023: [{...log},{...log}]}
    const logsGroupedByYears = _.sortBy(filteredLogs, 'creationDate').reduce((accumulator, log) => {
        const year = moment(log.creationDate).format('YYYY');
        if(accumulator[year]){
            accumulator[year] = [...accumulator[year], log];
        } else {
            accumulator = {...accumulator, [year]: [log]}
        }
        return accumulator;
    }, {});

    const [newLog, setNewLog] = useState('');
    const save = () => {
        createHistoryLog({ patientId, text: newLog, isPrivate: isPrivateChecked}).unwrap()
                .then(() => {
                    enqueueSuccess();
                })
                .catch(error => {
                    enqueueError();
                })
                .then(() => {
                    setNewLog('');
                });
    }

    const [isPrivateChecked, setIsPrivateChecked] = useState(false);
    const toggleIsPrivateChecked = () => setIsPrivateChecked(!isPrivateChecked);

    return (
        <Flex item container column>
            <Flex item container grow={0} style={{height: 59, marginTop: 10, marginBottom: 5, padding: 10,
                border: '1px solid lightgrey', borderRadius: 6, backgroundColor: '#f8f8fa'}}>
                <HmoAvatar {...{user}} style={{margin: 4}}/>
                <HmoTextField
                        value={newLog}
                        variant={'outlined'}
                        multiline
                        placeholder={translate('global.add-entry')}
                        onChange={event => setNewLog(event.target.value)}
                        fullWidth
                        name="comment"
                />
                <HmoFilledButton onClick={save} style={{marginLeft: 10}}>
                    {translate('global.save')}
                </HmoFilledButton>
            </Flex>
            <Flex item container alignItems={"center"} grow={0}>
                <Checkbox
                        style={{
                            padding: 3,
                            marginRight: 5,
                            display: "flex",
                            alignItems: "flex-start",
                        }}
                        checked={isPrivateChecked}
                        onChange={toggleIsPrivateChecked}
                />
                    <Typography style={{ fontSize: 14, display: "inline" }}>
                        {translate("history.private")}
                    </Typography>
                </Flex>
            <Flex item grow={0} fullWidth container style={{borderBottom: '1px solid black'}}>
                <Typography style={{fontWeight: 'bold', fontSize: 18}}>
                    {translate('history.interactions-title')}
                </Typography>
            </Flex>
            <Flex item container column style={{overflow: 'auto', height: '79vh'}}>
                {Object.keys(logsGroupedByYears).map(year =>
                    <Flex item container grow={0} style={{padding: 10, borderBottom: '1px dashed lightgrey'}}>
                        <Flex item container style={{maxWidth: 300}} center>
                            <Typography style={{fontWeight: 'bold', fontSize: 18}}>{year}</Typography>
                        </Flex>
                        <Flex item container column>
                        {
                            logsGroupedByYears[year].map(log =>
                                <Flex item container column>
                                    <Flex item container grow={0} alignItems={'center'} padding={3}>
                                        <Typography style={{marginRight: 7}}>{moment(log.creationDate).format('DD. MMM HH:mm')}</Typography>
                                        <HmoAvatar user={log} small/>
                                    </Flex>
                                    <Flex item grow={0} container column style={{
                                        padding: 10, border: '1px solid lightgrey',
                                        borderRadius: 6, backgroundColor: '#f8f8fa'
                                    }}>
                                        <Typography style={{color: log.historyTriggerType === 'MANUAL'
                                                    ? '#000066': 'grey', whiteSpace: "pre-wrap"}}>
                                            {log.text}
                                        </Typography>

                                    </Flex>
                                </Flex>
                            )
                        }
                    </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default HistoryLog;
