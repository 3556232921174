import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const nestedFieldConstructor = ({nestedPath = null, category = Category.OTHER, label, allowAndCondition = false, allowOrCondition = false, andConditionLabel = "", canExclude = false, nestedConfigs, virtualFieldPaths = [], additionalFieldProperties: additionalFieldsProperties = {}, showLabel = true, }) => {
    const fieldName = nestedPath || nestedConfigs.map((nestedConfig) => Object.keys(nestedConfig)[0]).join("|");
    const nestedValues = nestedConfigs.map((nestedConfig) => {
        const field = Object.keys(nestedConfig)[0];
        const additionalFieldProperties = additionalFieldsProperties[field] || {};
        return {
            properties: {
                field,
                virtualField: virtualFieldPaths.includes(field) || undefined,
                value: "",
                ...additionalFieldProperties,
            },
            "type": "rule"
        }
    });
    const mainNestedConfig = {
        [fieldName]: {
            widgetType: WidgetType.NESTED,
            category: category,
            widgetConfig: {
                label,
                showLabel,
                allowOrCondition,
                allowAndCondition,
                andConditionLabel,
                canExclude,
            },
            defaultRuleProperties: {
                exclude: false,
                nestedPath: nestedPath ? nestedPath : undefined,
                value: {
                    type: "nested",
                    nestedValues: nestedValues,
                }
            },
        },
    }
    const nestedConfigWithNestedComponents = nestedConfigs.reduce((acc, current) => {
      const [key, value] = Object.entries(current)[0];
      return { ...acc, [key]: value };
    }, mainNestedConfig);
    return nestedConfigWithNestedComponents;
};

    // medications: {
    //     widgetType: WidgetType.NESTED,
    //     category: Category.MEDICATIONS,
    //     widgetConfig: {
    //         label: "medication.ingredient",
    //         showLabel: false,
    //         allowOrCondition: true,
    //         allowAndCondition: true,
    //         andConditionLabel: "cohort.add-to-group.medication",
    //         canExclude: true,
    //     },
    //     defaultRuleProperties: {
    //         exclude: false,
    //         value: {
    //             type: "nested",
    //             nestedValues: [
    //                 {
    //                     type: "rule",
    //                     properties: {
    //                         field: "medications.ingredient",
    //                         value: "",
    //                     },
    //                 },
    //                 {
    //                     type: "rule",
    //                     properties: {
    //                         field: "medCriteria",
    //                         virtualField: true, // maybe this should not be a part of stored rule, but for now it is
    //                         value: "",
    //                     },
    //                 },
    //             ],
    //         },
    //     },
    // },
