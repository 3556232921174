import { Category } from '../categories';
import { WidgetType } from '../widgetTypes';
export const ageRangeFieldConstructor = ({ pathToValue, category = Category.OTHER, label, fieldWidth = 48, nestedComponent = false, defaultFromValue, defaultToValue }) => ({
    [pathToValue]: {
        widgetType: WidgetType.RANGE_FILTER,
        category: nestedComponent ? undefined : category,
        defaultRuleProperties: {
            value: {
                type: "dateRange",
                from: defaultFromValue,
                to: defaultToValue
            },
        },
        widgetConfig: {
            width: fieldWidth,
            label
        },
    },

});
