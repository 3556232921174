import Flex from "components/grid/Flex";
import { Box, Button, Checkbox, Tooltip, Typography } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import utils from "utils/Utils";
import TagsCell from "./component/TagsCell";
import moment from "moment";
import IconWithCounter from "./component/IconWithCounter";
import { getStepStatus } from "scenes/patient/functions";
import { ItemStatusEnum } from "domain/Step.model";
import { pick, flow, assoc, uniq } from "lodash/fp";
import bloodDropIconSrc from "assets/img/blood-drop.png";
import studyIconSrc from "assets/img/scientist.png";
import Tag from "components/tags/Tag";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useRemoveTagMutation } from "../patient/TagApi";
import { useRemovePendingDeletionMutation } from "../patient/PendingDelitionApi";
import { deletionTimeToHrsMins } from "./functions/timeTillDeletion";
import { useIsCaseManager } from "utils";
import { isManager } from "domain/User.model";
import { useSelector } from "react-redux";
import { ImportExport } from "@material-ui/icons";

export const Cell = (props) => {
    const { text, bold, transparent, item, color, style, center = true, left, textStyle, children, ...other } = props;
    const positioningStyles = left ? { paddingLeft: "14px", width: "5%", alignItems: "center" } : {};
    return (
        <Flex
            item={item || "1 0 5%"}
            container
            center={center}
            style={{ width: "5%", ...positioningStyles, ...style }}
            {...other}
        >
            {text && (
                <Typography
                    style={{
                        color: color || "#030303",
                        opacity: transparent ? 0.6 : 1,
                        bold: bold ? "bold" : "unset",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        ...textStyle,
                    }}
                >
                    {text}
                </Typography>
            )}
            {children && children}
        </Flex>
    );
};

const mapAgreementToIcon = (agreement) => {
    switch (agreement) {
        case "BIO_SAMPLES":
            return bloodDropIconSrc;
        case "STUDY":
            return studyIconSrc;
        default:
            return "";
    }
};

const getAgreementsToShow = flow(
    (x) => x ?? "",
    (x) => (x === "" ? [] : x.split(",")),
    (x) => x.filter((x) => x !== ""),
    (x) => x.sort((a, b) => a.localeCompare(b)),
);

const PatientRow = (props) => {
    const {
        patientOverview,
        displayForContactPerson,
        selectPatient,
        selectedPrograms,
        toggleProgram,
        refresh,
        checkedPatientIds,
        checkPatient,
        handleOnRightClick,
        style,
    } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const user = useSelector((state) => state.mainReducer.user);
    const [removeTag] = useRemoveTagMutation();
    const [removePendingDeletionMutation] = useRemovePendingDeletionMutation();
    const caseManagerRoleSelected = useIsCaseManager();
    const deleteTag = (id) => (event) => {
        event.preventDefault();
        event.stopPropagation();
        removeTag({ id }).unwrap().then(refresh);
    };
    const agreementsToShow = getAgreementsToShow(patientOverview.agreements);
    const {
        date,
        description,
        place,
        unseenChats,
        unseenDocuments,
        unseenContacts,
        programs,
        programsData = [],
        tagsData = [],
    } = patientOverview;
    // TODO: This is a temporary solution to display the programs for the contact person, it's better to have a normal programsData response from backend
    const mappedProgramsData = displayForContactPerson
        ? (programs || "").split(",").map((p) => ({
              shortName: p,
              requestToUnsubscribe: false,
          }))
        : programsData;

    const requestedToUnsubscribeNames = uniq(
        (mappedProgramsData || [])
            .filter((program) => program.requestToUnsubscribe)
            .map((program) => program.shortName),
    );

    const itemStatus = getStepStatus({ date, isCompleted: false }) || ItemStatusEnum.Normal;

    const itemStatusColor = (() => {
        switch (itemStatus) {
            case ItemStatusEnum.Completed:
                return theme.palette.primary.light;
            case ItemStatusEnum.Upcoming:
                return theme.palette.warning.dark;
            case ItemStatusEnum.Overdue:
                return theme.palette.error.light;
            case ItemStatusEnum.Normal:
            default:
                return "initial";
        }
    })();

    const markedForDeletionDateToFmtStr = (date) => {
        const format = i18n.language === "en" ? "Do of MMMM H:mm" : "Do MMMM YYYY [um] H:mm";
        return moment(date).locale(i18n.language).format(format);
    };

    const patientOverviewToPendingDeletionTranslationArgs = flow(
        pick(["deletionInitiatorTitle", "deletionInitiatorFamilyName", "deletionInitiatorFullName"]),
        assoc("wasMarkedForDeletionAtFmtStr", markedForDeletionDateToFmtStr(patientOverview.wasMarkedForDeletionAt)),
    );

    const checked = checkedPatientIds.includes(patientOverview.patientId || patientOverview.id);
    const onCheck = (event) => {
        event.stopPropagation();
        event.preventDefault();
        checkPatient(patientOverview.patientId || patientOverview.id);
    };
    return (
        <div style={style}>
            <Flex
                item
                grow={0}
                container
                style={{
                    border: patientOverview?.pendingDeletion ? "1px solid rgb(140,120,120)" : "1px solid #E2E1E4",
                    borderTop: patientOverview?.pendingDeletion ? "1px solid rgb(120,120,120)" : "unset",
                    height: 40,
                    backgroundColor: patientOverview?.pendingDeletion ? "#f8d7da" : "white",
                }}
                onClick={selectPatient(patientOverview.patientId || patientOverview.id)}
                {...{
                    ...(isManager(user) && { onContextMenu: handleOnRightClick }),
                }}
            >
                <div style={{ backgroundColor: itemStatusColor, width: 4 }} />
                {isManager(user) && (
                    <Checkbox
                        {...{
                            size: "small",
                            checked,
                            // onChange: onCheck,
                            onClick: onCheck,
                        }}
                    ></Checkbox>
                )}

                <Tooltip title={!patientOverview?.userId ? t("global.patient-without-user") : ""}>
                    <Flex item={"1 0 5%"} container style={{ paddingLeft: "14px", width: "5%", alignItems: "center" }}>
                        <Typography
                            style={{
                                color: !patientOverview?.userId ? "darkgray" : "#030303",
                                opacity: 1,
                                bold: "unset",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                fontStyle: !patientOverview?.userId ? "italic" : "initial",
                            }}
                        >
                            {utils.formattedUserNameWithComma(patientOverview)}&nbsp;
                        </Typography>
                        {patientOverview?.pendingDeletion && (
                            <Tooltip
                                title={
                                    <div>
                                        {t(
                                            "patient.pending-deletion-tooltip",
                                            patientOverviewToPendingDeletionTranslationArgs(patientOverview),
                                        )}
                                        <br />
                                        <br />
                                        {t(
                                            "patient.time-till-deletion",
                                            deletionTimeToHrsMins(new Date(), patientOverview.wasMarkedForDeletionAt),
                                        )}
                                    </div>
                                }
                                placement="top"
                            >
                                <button
                                    disabled={!caseManagerRoleSelected}
                                    style={{
                                        marginLeft: theme.spacing(1),
                                        backgroundColor: caseManagerRoleSelected
                                            ? "#CF3A59"
                                            : theme.palette.error.light,
                                        padding: theme.spacing(0.5, 2),
                                        borderRadius: theme.shape.borderRadius,
                                        border: "1px solid gray",
                                        color: theme.palette.error.contrastText,
                                        cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // both types have patientId but userId only for registered users
                                        removePendingDeletionMutation({
                                            entityType: "PATIENT",
                                            entityId: patientOverview.patientId,
                                        })
                                            .unwrap()
                                            .then(refresh);
                                    }}
                                >
                                    Undelete
                                </button>
                            </Tooltip>
                        )}
                    </Flex>
                </Tooltip>

                <TagsCell
                    {...{
                        programsData: mappedProgramsData,
                        requestedToUnsubscribeNames,
                        selectedPrograms,
                        toggleProgram,
                    }}
                />
                <Cell left center={false} {...{ text: place }} />
                <Cell left center={false} {...{ text: description }} />
                <Cell
                    left
                    center={false}
                    color={itemStatusColor}
                    {...{ text: date ? moment(date).format("DD MMMM YYYY") : "" }}
                />
                <Flex item={"1 0 5%"} container alignItems="center" style={{ width: "5%", paddingLeft: "14px" }}>
                    {patientOverview?.thirdPartyId && (
                        <Tooltip title={t("global.imported") + " Patient"}>
                            <ImportExport style={{ fill: "grey" }} />
                        </Tooltip>
                    )}
                    {agreementsToShow?.length > 0 && (
                        <Flex item={"0 0 80px"} container alignItems="center">
                            {agreementsToShow.map((agreement, index) => {
                                return (
                                    agreement && (
                                        <Tooltip key={agreement + index} title={t(`agreements.${agreement}`)}>
                                            <img
                                                src={mapAgreementToIcon(agreement)}
                                                width={22}
                                                height={22}
                                                style={{ marginRight: "10px" }}
                                                alt={""}
                                            />
                                        </Tooltip>
                                    )
                                );
                            })}
                        </Flex>
                    )}

                    <Flex item container alignItems="center">
                        {tagsData?.map((tag, index) => {
                            return (
                                <Tag
                                    key={tag?.tag + index}
                                    {...{
                                        tag: tag?.tag,
                                        selected: true,
                                        noBorder: true,
                                        selectedBackgroundColor: "orange",
                                        tooltipProps: {
                                            interactive: true,
                                        },
                                        tooltip: (
                                            <Flex container column center>
                                                <Typography style={{ fontSize: 13 }}>
                                                    {t("patient.potential-candidate", {
                                                        tag: tag?.tag,
                                                        taggerFullName: tag?.taggerFullName,
                                                        creationDate: moment(tag?.creationDate).format("D. MMM YYYY"),
                                                    })}
                                                </Typography>
                                                <Button
                                                    variant={"contained"}
                                                    color="secondary"
                                                    onClick={deleteTag(tag?.id)}
                                                >
                                                    {t("patient.remove-tag")}
                                                </Button>
                                            </Flex>
                                        ),
                                    }}
                                />
                            );
                        })}
                    </Flex>
                    <Flex
                        item={"1 0 5%"}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{ width: "5%" }}
                    >
                        <IconWithCounter
                            style={{ marginRight: 10 }}
                            count={unseenDocuments}
                            Icon={InsertDriveFileOutlinedIcon}
                            tooltip={t("patient.unseen-documents", { count: unseenDocuments || 0 })}
                        />
                        <IconWithCounter
                            style={{ marginRight: 10 }}
                            count={unseenChats}
                            Icon={ChatBubbleOutlineIcon}
                            tooltip={t("patient.unseen-chats", { count: unseenChats || 0 })}
                        />
                        <IconWithCounter
                            style={{ marginRight: 10 }}
                            count={unseenContacts}
                            Icon={VisibilityOutlinedIcon}
                            tooltip={t("patient.unseen-contacts", { count: unseenContacts || 0 })}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
};

export default PatientRow;
